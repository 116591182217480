import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import {
  Accent,
  Description,
  SectionContent,
  TitleLarge
} from '../../common/styles'

import ContactForm from './ContactForm'
import { ContactCardSection, FormContainer } from './ContactSection.styles'

const Title = styled(TitleLarge)`
  text-align: start;
`

const query = graphql`
  query {
    strapiContact {
      illustration {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 300, formats: [AUTO, WEBP])
          }
        }
      }
    }
    strapiGlobal {
      contactEmail
      contactPhone
    }
  }
`

const ContactSection = () => {
  const {
    strapiGlobal: { contactEmail, contactPhone }
  } = useStaticQuery(query)

  return (
    <ContactCardSection>
      <SectionContent>
        <Title>
          <Accent>Contactez</Accent>-moi! ✉️
        </Title>
        <Description>
          <p>
            Pour toute prise de rendez-vous, demande de renseignement, de
            disponibilité...
          </p>
          <p>
            Je m&apos;engage à revenir vers vous dans les plus brefs délais !
          </p>

          <p>
            Vous pouvez également me contacter directement par email à
            l&apos;adresse{' '}
            <Accent>
              <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
            </Accent>{' '}
            ou par téléphone au{' '}
            <Accent>
              <a href={`tel:${contactPhone}`}>{contactPhone}</a>
            </Accent>
            .
          </p>
        </Description>
        <FormContainer>
          <ContactForm />
        </FormContainer>
      </SectionContent>
    </ContactCardSection>
  )
}

export default ContactSection
