import styled from 'styled-components'

import { CardSection } from '../../common/styles'
import breakpoint from '../../constants/breakpoint'
import colors from '../../constants/colors'
import spacing from '../../constants/spacing'

export const ContactCardSection = styled(CardSection)`
  margin: 0;

  @media ${breakpoint.TABLET} {
    margin: ${spacing.MAIN_PADDING};
  }
`

export const FormContainer = styled.div`
  flex: 1;
  margin-bottom: 0;
  padding: ${spacing.MEDIUM_PADDING};
  border: solid 1px ${colors.pink};
  border-radius: 20px;

  p {
    margin-bottom: 7px;
  }

  input,
  textarea {
    margin-top: 14px;
  }

  @media ${breakpoint.TABLET} {
    padding: ${spacing.MAIN_PADDING};
  }
`
