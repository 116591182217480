import { graphql, PageProps } from 'gatsby'

import Layout from '../../layouts'
import ContactSection from '../../sections/ContactSection'

const Contact = ({
  data: { strapiContact }
}: PageProps<Queries.ContactQuery>) => (
  <Layout
    smallNavBar
    seo={{
      title: strapiContact?.seo?.title,
      description: strapiContact?.seo?.description
    }}
  >
    <ContactSection />
  </Layout>
)

export default Contact

export const query = graphql`
  query Contact {
    strapiContact {
      seo {
        description
        title
      }
    }
  }
`
